export const TEXT = 1;
export const INTERPOLATOR = 2;
export const TRIPLE = 3;
export const SECTION = 4;
export const INVERTED = 5;
export const CLOSING = 6;
export const ELEMENT = 7;
export const PARTIAL = 8;
export const COMMENT = 9;
export const DELIMCHANGE = 10;
export const ANCHOR = 11;
export const ATTRIBUTE = 13;
export const CLOSING_TAG = 14;
export const COMPONENT = 15;
export const YIELDER = 16;
export const INLINE_PARTIAL = 17;
export const DOCTYPE = 18;
export const ALIAS = 19;

export const AWAIT = 55;

export const NUMBER_LITERAL = 20;
export const STRING_LITERAL = 21;
export const ARRAY_LITERAL = 22;
export const OBJECT_LITERAL = 23;
export const BOOLEAN_LITERAL = 24;
export const REGEXP_LITERAL = 25;

export const GLOBAL = 26;
export const KEY_VALUE_PAIR = 27;

export const REFERENCE = 30;
export const REFINEMENT = 31;
export const MEMBER = 32;
export const PREFIX_OPERATOR = 33;
export const BRACKETED = 34;
export const CONDITIONAL = 35;
export const INFIX_OPERATOR = 36;

export const INVOCATION = 40;

export const SECTION_IF = 50;
export const SECTION_UNLESS = 51;
export const SECTION_EACH = 52;
export const SECTION_WITH = 53;
export const SECTION_IF_WITH = 54;

export const ELSE = 60;
export const ELSEIF = 61;
export const THEN = 62;
export const CATCH = 63;

export const EVENT = 70;
export const DECORATOR = 71;
export const TRANSITION = 72;
export const BINDING_FLAG = 73;
export const DELEGATE_FLAG = 74;

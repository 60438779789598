// Match one or more characters until: ", ', \, or EOL/EOF.
// EOL/EOF is written as (?!.) (meaning there's no non-newline char next).
const stringMiddlePattern = /^(?=.)[^"'\\]+?(?:(?!.)|(?=["'\\]))/;

// Match one escape sequence, including the backslash.
export const escapeSequencePattern = /^\\(?:[`'"\\bfnrt]|0(?![0-9])|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4}|(?=.)[^ux0-9])/;

// Match one ES5 line continuation (backslash + line terminator).
export const lineContinuationPattern = /^\\(?:\r\n|[\u000A\u000D\u2028\u2029])/;

// Helper for defining getDoubleQuotedString and getSingleQuotedString.
export default function(okQuote) {
  return function(parser) {
    let literal = '"';
    let done = false;
    let next;

    while (!done) {
      next =
        parser.matchPattern(stringMiddlePattern) ||
        parser.matchPattern(escapeSequencePattern) ||
        parser.matchString(okQuote);
      if (next) {
        if (next === `"`) {
          literal += `\\"`;
        } else if (next === `\\'`) {
          literal += `'`;
        } else {
          literal += next;
        }
      } else {
        next = parser.matchPattern(lineContinuationPattern);
        if (next) {
          // convert \(newline-like) into a \u escape, which is allowed in JSON
          literal += '\\u' + ('000' + next.charCodeAt(1).toString(16)).slice(-4);
        } else {
          done = true;
        }
      }
    }

    literal += '"';

    // use JSON.parse to interpret escapes
    return JSON.parse(literal);
  };
}

export default {
  'accept-charset': 'acceptCharset',
  accesskey: 'accessKey',
  bgcolor: 'bgColor',
  class: 'className',
  codebase: 'codeBase',
  colspan: 'colSpan',
  contenteditable: 'contentEditable',
  datetime: 'dateTime',
  dirname: 'dirName',
  for: 'htmlFor',
  'http-equiv': 'httpEquiv',
  ismap: 'isMap',
  maxlength: 'maxLength',
  novalidate: 'noValidate',
  pubdate: 'pubDate',
  readonly: 'readOnly',
  rowspan: 'rowSpan',
  tabindex: 'tabIndex',
  usemap: 'useMap'
};
